<template>
    <v-container>
      <!-- {{ pixels }}
      <p>--------------</p>
      {{ arrayCodes }}
      <p>--------------</p>
      {{ pixelsComplete }} -->
      <v-row>
        <v-col cols="6">
          <h3 class="mt-3">Elementos Escaneados: {{ arrayCodes.length }}</h3>
          <!-- <v-btn color="primary" @click="assign(tucaneCode, anukinCode)">asignar</v-btn> -->
        </v-col>
        <v-col cols="6" class="mt-3">
          <v-btn class="white--text" color="black" @click="saleItems" :disabled="arrayCodes.length == 0">Marcar como vendidos</v-btn>
          <v-btn class="ml-6" color="error" @click="cleanArray" :disabled="arrayCodes.length == 0">Eliminar Todos</v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-text-field v-on:keyup.enter=addToArrayCodes() ref="anukin" v-model="anukinCode" color="black" label="Código anukin" shaped outlined />
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="12">
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Código Anukin</th>
                  <th class="text-left">Sku</th>
                  <th class="text-left">Color</th>
                  <th class="text-left">Modelo</th>
                  <th class="text-left">Sexo</th>
                  <th class="text-left">Talla</th>
                  <th class="text-left">Acciones</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in pixelsComplete" :key="index">
                  <td>{{ item.assetId }}</td>
                  <td>{{ item.sku }}</td>
                  <td>{{ item.color }}</td>
                  <td>{{ item.modelo }}</td>
                  <td>{{ item.sexo }}</td>
                  <td>{{ item.talla }}</td>
                  <td><v-btn small color="error" @click="deleteFromArray(index)">Eliminar</v-btn></td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>
  
        <v-snackbar v-model="snackbar" :timeout="timeout">Código anukin duplicado</v-snackbar>
  
    </v-container>
  </template>

<script>
import router from '../router/index'
import Swal from 'sweetalert2'
import axios from "axios";
import { nextTick } from 'process'
import { mapState } from 'vuex';

export default {
  data() {
    return {
      anukinCode: '',
      arrayCodes: [],
      arrayCodesDetails: [],

      //Snackbar
      snackbar: false,
      timeout: 2000,
    }
  },

  computed: {
    ...mapState(['pixels', 'catalog']),

    pixelsComplete () {
      if(this.arrayCodes.length > 0){
        return this.arrayCodes.map(item => {
          const pixelItem = this.pixels.find(({assetId}) => assetId == item)
          //console.log(pixelItem)
          const skuItem = this.catalog.find(({sku}) => sku == pixelItem.sku)
          //console.log(skuItem)
          return {
            assetId : pixelItem.assetId,
            sku : pixelItem.sku,
            color : skuItem.color,
            modelo : skuItem.modelo,
            sexo : skuItem.sexo,
            talla : skuItem.talla
          }
        })
        
      } else {
        return 
      }
    }
  },

  methods: {
    async assign(anukin) {
      this.arrayCodes.unshift(anukin)
    },

    deleteFromArray(index) {
      this.arrayCodes.splice(index, 1);
      this.$refs.anukin.focus();
    },

    cleanArray () {
      this.arrayCodes = []
      this.$refs.anukin.focus();
      nextTick(() => {
          this.anukinCode = ''
      })
    },

    async saleItems () {
      try {
        //const response = await axios.post('http://localhost:3300/sale', {
        const response = await axios.post('https://tucane-mqtt-client.herokuapp.com/sale', {  
          toSale: this.arrayCodes
        }) 
        console.log(response)
        if(response.status == 200){
          Swal.fire({
            //position: 'top-end',
            icon: 'success',
            title: 'Elementos vendidos correctamente',
            showConfirmButton: false,
            timer: 1800
          })
        } else {
          Swal.fire({
            //position: 'top-end',
            icon: 'error',
            title: 'Error al vender los elementos',
            showConfirmButton: false,
            timer: 1800
          })
        }
        this.cleanArray()
        router.push('/')
      } catch (error) {
        console.log(error)
      }  
    },

    addToArrayCodes(){
      const anukinCodeFix = this.anukinCode.substring(22)
      if(!this.arrayCodes.some(item => item == anukinCodeFix)){
        //console.log("Sold: ", anukinCodeFix)
        this.assign(anukinCodeFix)
        this.$refs.anukin.focus();
        nextTick(() => {
          this.anukinCode = ''
        })
      }
      else {
        this.snackbar = true
        this.$refs.anukin.focus();
        nextTick(() => {
          this.anukinCode = ''
        })
      }
    }
  
  },


  mounted() {
    this.$refs.anukin.focus();
  },
}
</script>